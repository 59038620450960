<template>
  <section class="content-login">
    <div class="content-login-itens container">
      <router-link tag="a" :to="{name: 'login'}" class="content-login-itens--logo">
        <img class="img-fluid" src="@/assets/img/logo-grupo-nabeiro.png" alt="logo grupo nabeiro" title="logo grupo nabeiro"/>
      </router-link>
      <form ref="formLogin" @submit.prevent="onLogin" class="content-login-itens-form">
        <div class="content-login-itens-form--title">
          <h1>Bem vindo<span>+ clientes + amigos</span></h1>
        </div>
        <div class="content-login-itens-form-inputs">
          <div class="row">
            <!-- <div class="col-lg-4">
              <div class="content-input">
                <input type="text" name="id_colaborador" value="" placeholder="ID colaborador" required/>
              </div>
            </div> -->
            <div class="col-lg-4">
              <div class="content-input">
                <input type="email" name="email" value="" placeholder="E-mail" required />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="content-input">
                <input type="password" name="password" value="" placeholder="Password" required/>
              </div>
            </div>
          </div>
        </div>
        <div class="content-login-itens-form-btns">
          <div class="row">
            <div class="col-12">
              <button type="submit" class="btn--black">
                <p>Entrar</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9"><path d="M4.5,0,9,8H0Z" transform="translate(8) rotate(90)"/></svg>
              </button>
            </div>
            <div class="col-12">
              <router-link class="btn--link" tag="a" :to="{name: 'recupery-password'}">Recuperar password</router-link>
            </div>
            <div class="col-12">
              <router-link tag="a" :to="{name: 'register'}" class="btn--red">
                <p>Criar conta</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9"><path d="M4.5,0,9,8H0Z" transform="translate(8) rotate(90)"/></svg>
              </router-link>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import axios from 'axios';
export default {
  methods: {
    ...mapActions('App', ['loading']),
    ...mapActions('User', ['setUser', 'setToken']),
    async onLogin(el){            
        let formData = new FormData(this.$refs.formLogin)

        this.loading(true);
        await axios({
            method: 'POST',
            url: '/api/v1/campaign/user/login',
            data: formData
        }).then(response => response.data)
        .then((data) => {
            this.loading(false);
            this.setUser(data.data);
            this.setToken(data.data.token);
            this.$router.push({
              name: 'home'
            })
        })
        .catch((error) => {
            this.loading(false);
            console.log(error.response.data.message);
            this.$notify({                    
                type: "warn",
                text: error.response.data.message,
                duration: 1000,
            })
        })
    }
  }
}
</script>

<style lang="scss">
@import './index.scss';
</style>
